<template>
  <div class="container-fluid">
    <div class="page-title-box">
      <bread-crumb :breadcrumbs="[{ label: 'Командировки' }]" />
      <h4 class="page-title">Командировки</h4>
      <div
        class="trip_import_btn gant_button_month"
        @click="dialogImport = true"
      >
        Парсер
      </div>
    </div>
    <GridView
      :service="service"
      :actions="[
        {
          name: 'create',
          routeName: 'TripCreate',
        },
      ]"
      :export="false"
      :columns="[
        {
          field: 'where',
          header: 'Куда',
          sortable: true,
          slot: 'titleLink',
          filter: {
            label: 'Куда',
            field: 'where',
            type: 'text',
          },
        },
        {
          field: 'start',
          header: 'Срок',
          slot: 'twoDate',
          sortable: true,
        },
        {
          field: 'olderName',
          header: 'Старший группы',
          slot: 'olderNameSlot',
          filter: {
            label: 'Старший группы',
            field: 'older',
            type: 'multiselect',
            options: userList,
          },
        },
        {
          header: 'Состав группы',
          field: 'employees',
          slot: 'emplyeeList',
        },
        {
          header: 'Отчёт',
          field: 'return',
          slot: 'returnField',
        },
        {
          actions: [
            {
              action: 'show',
              routeName: 'TripShow',
            },
            {
              action: 'edit',
              routeName: 'TripEdit',
            },
            {
              action: 'delete',
            },
          ],
        },
      ]"
      :search="[
        {
          field: 'where',
          label: 'Куда',
        },
        {
          label: 'Старший группы',
          field: 'older',
          type: 'multiselect',
          options: userList,
        },
      ]"
    >
      <template #olderNameSlot="{ data }">
        <router-link
          class="text-info link_underline"
          :to="{ name: 'UserShow', params: { id: data.older } }"
        >
          {{ data.olderName }}
        </router-link>
      </template>
      <template #emplyeeList="{ data }">
        <template v-for="(employee, i) in data.employees" :key="i">
          <router-link
            class="text-info link_underline"
            :to="{ name: 'UserShow', params: { id: employee.id } }"
          >
            {{ employee.surname }} {{ employee.name }}
            {{ employee.second_name }} <br
          /></router-link>
        </template>
      </template>
      <template #twoDate="{ data }">
        {{ Str.date(data.start) }} <br />
        {{ Str.date(data.end) }}
      </template>
      <template #returnField="{ data }">
        <span v-if="data.reportFile == null && data.reportText == null">
          Отсутствует
        </span>
        <div v-else-if="data.return == 0">
          <span class="text-warning">
            Предоставлен
            <a v-if="data.reportFile != null" :href="data.reportFile"
              ><p class="text-info">Скачать</p></a
            >
          </span>
        </div>
        <div v-else-if="data.return == 1">
          <span class="text-success"> Принят </span>
          <a v-if="data.reportFile != null" :href="data.reportFile"
            ><p class="text-info">Скачать</p></a
          >
        </div>
      </template>
      <template #titleLink="{ data }">
        <router-link
          class="text-info link_underline"
          :to="{ name: 'TripShow', params: { id: data.id } }"
        >
          {{ data.where }}
        </router-link>
      </template>
    </GridView>
    <Dialog
      :modal="true"
      header="Импорт командировок"
      v-model:visible="dialogImport"
    >
      <a :href="this.apiUrl + '/storage/public/test.csv'"
        >Скачать пример файла</a
      >
      <FileUpload
        name="parseTrip"
        chooseLabel="Выбрать файл"
        uploadLabel="Загрузить"
        cancelLabel="Отмена"
        :url="urlForImport"
        @upload="this.importSuccess('success')"
        @error="this.importSuccess('error')"
      />
    </Dialog>
    <Toast />
  </div>
</template>

<script>
import BreadCrumb from "@/components/ui/BreadCrumb";
import GridView from "@/components/ui/GridView.vue";
import TripService from "../services/TripService";
import UserService from "@/modules/user/services/UserService";
import Str from "@/utils/Str";
import Dialog from "primevue/dialog";
import FileUpload from "primevue/fileupload";
import Toast from "primevue/toast";

export default {
  components: {
    BreadCrumb,
    GridView,
    Dialog,
    FileUpload,
    Toast,
  },
  data() {
    return {
      Str: Str,
      userList: {},
      service: new TripService(),
      userService: new UserService(),
      dialogImport: false,
      importFile: null,
      urlForImport: process.env.VUE_APP_API_URL + "/trip/parse",
      apiUrl: process.env.VUE_APP_API_URL,
    };
  },
  async created() {
    this.userList = await this.userService.allList();
  },
  methods: {
    importSuccess(response) {
      if (response == "success") {
        this.dialogImport = false;
        this.$toast.add({
          severity: "success",
          summary: "Импорт",
          detail: "Командировки успешно импортированы",
          life: 3000,
        });
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Ошибка",
          detail: "Что-то пошло не так...",
          life: 3000,
        });
      }
    },
  },
};
</script>
