import Service from '../../../services/Service';

class TripService extends Service {

    basePath = 'trip';

    newObject() {
        return new TripService();
    }
    
}

export default TripService;